import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ContentCard from '../components/Layout/ContentCard';
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo"

export const query = graphql`
  query {
    allNodeProduct(sort: {fields: created, order: DESC}) {
      edges {
        node {
          title
          drupal_internal__nid
          field_teaser
          created
          path {
            alias
          },
          relationships {
            featuredImage: field_image {
              localFile {
                childImageSharp {
                  fixed(width: 100, height: 100, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }`

const ProductPage = ({ data,location }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <section>
      <div className="container mx-auto px-8">
        <div className="page-title">Made in USA Bicycle Products</div>
        {data.allNodeProduct.edges.map(product => (
        <>
          <ContentCard data={product.node} location={location} className="float-left m-3 w-1/3" />
        </>
      ))}
      </div>
    </section>
  </Layout>
)

ProductPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default ProductPage